import React, { useState } from 'react';
import { Modal, Container, Row, Col } from 'react-bootstrap';

import oldAwardImg from '../images/5star-2010.gif'
import awardImg from '../images/5-star-wealth-manager-michael-mueller.jpg'

const AwardsBox = () => {
  const [show, setShow] = useState(false);
  const [selectedAward, setSelectedAward] = useState('');
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const setModalContent = (awardWinner) => {
    setSelectedAward(awardWinner);
    handleShow();
  }
  return (
    <>
      <div className="awardsBox" style={styles.awardsbox}>
        <h4 style={{textAlign: 'center', color: '#8d1b38'}}>If awards didn't mean anything, everyone would get one.</h4>
        <div style={styles.awardModal}>
        <div className="awardsbox--inner">
          <img className="awardsbox--image oldaward" src={oldAwardImg} /><br/>
          <img className="awardsbox--image" src={awardImg} onClick={e => setModalContent('Michael Mueller')} />
        </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header style={styles.awardsModalDisplay} closeButton>
            <Modal.Title style={{color: '#8d1b38'}} id="example-modal-sizes-title-lg">
              {selectedAward} wins FIVE STAR Wealth Manager Award Again in 2021!
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={styles.awardsModalDisplay}>
            <Container fluid={true}>
              <Row>
                <Col lg={6}>
                  <p>
                    FIVE STAR Best in Client Satisfaction Wealth Managers is one of the highest awards given to financial advisors. The winning advisors are selected as a result of a rigorous research process<sup>1</sup> and represent no more than 5% of the wealth managers within their market area.
                  </p>
                </Col>
                <Col lg={6} style={{textAlign: 'center'}}>
                {
                  selectedAward === 'Michael Mueller'
                  ? <img src={awardImg} width="200" />
                  : <img src={awardImg} width="200" />  
                }
                </Col>
              </Row>
              <p>Wealth managers do not pay a fee to be included in the evaluation process or to be named as a FIVE STAR Wealth Manager<sup>SM</sup>. In connection with publication of the articles that appear in magazines, wealth managers may elect to pay a fee to include a photo and certain profile information in the “Profiles” section that accompanies the article.</p>
              <p style={{fontSize: '.75em'}}>
              1 The research is from Crescendo Business Services, LLC (the Company), a Minneapolis, MN based company, who contracts an independent third party research firm, Quantitative Market Intelligence (QMI), and partners with city and regional magazines to administer its rigorous FIVE STAR Wealth ManagerSM evaluation process and to prepare an article describing the survey and evaluation process, along with their results.
              </p>
            </Container>
          </Modal.Body>
        </Modal>
      </div>
    </>

  )
}

let styles = {
  awardModal: {
    cursor: 'pointer'
  },
  awardsModalDisplay: {
    backgroundColor: '#bdb7a1',

  }
}

export default AwardsBox