import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react";
import './style.css';

import sailingImg from '../images/logo_home.gif'

const Header = ({ siteTitle }) => (
  <header>
    <div className="header--img">
      <div
        style={{
          margin: `0 auto`,
          padding: `1.45rem 1rem`,
        }}
        className="header--container"
      >
        <Link to="/" className="headerLink" > Home &nbsp;  </Link>| &nbsp;
        <Link to="/contact" className="headerLink" > Contact Us &nbsp;  </Link>| &nbsp;
        {/* <Link to="/coastal-university" className="headerLink" > Coastal University &nbsp;  </Link>| &nbsp; */}
        <Link to="/login" className="headerLink" > Client Log-in </Link>
      </div>
      <Link style={styles.headerLogo} to="/">
        <img src={sailingImg} />
      </Link>
      </div>
  </header>
)

let styles = {
  headerLogo: {
    display: 'table',
    margin: '0 auto',
    marginTop: '-40px'
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
