import React from "react";
import Helmet from 'react-helmet'
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";

import './layout.css';

import FAB from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';

import Header from "./header";
import Sidenav from './sidenav';
import RightBar from './rightbar';
import Footer from './footer';

const Layout = ({children, location}) => {
  const [open, setOpen] = React.useState(false);
  const [pageName] = React.useState((location !== undefined && location.pathname !== undefined && location.pathname.replace(/\//g, "") !== '') ? location.pathname.replace(/\//g, "") : 'index');
  const [pages] = React.useState([
    'index', 'awards', 'philosophy', 'client-services',
    'account-services', 'case-studies', 'values',
    'mission-vision', 'why-coastal', 'about', 'michael-mueller',
    'crew', 'get-started'
  ]);
  const [lastPageIndex] = React.useState(pages.indexOf(pageName) - 1);
  const [nextPageIndex] = React.useState(pages.indexOf(pageName) + 1);

  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
  `)
  
  const toggleDrawer = (open) => event =>{
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(open);
  }
  
  const ShowLeftFab = ({pages, index, currentPage}) => {
    if(index < 0) {
      return null
    }
    if(pages[index] === 'index') var home = ''

    return (
      <>
        <Link className="fab-link" to={`/${(home === '') ? home : pages[index]}`}>
          <FAB className="menu-fab" variant="extended" color="secondary" aria-label="add" style={{position: 'fixed', bottom: 20, left: 20, outline: 'none', backgroundColor: '#8d1b38'}}>
            {(pages[index] === 'index') ? 'Home' : (pages[index].replace(/-/g, " ") === 'mission vision') ? 'mission & vision' : pages[index].replace(/-/g, " ") }
          </FAB>
        </Link>
      </>
    )
  }
  
  const ShowRightFab = ({pages, index, currentPage}) => {
    if(currentPage === 'login' || currentPage === 'contact' || index > pages.length - 1 || index < 0) {
      return null
    }
    return (
      <>
        <Link className="fab-link" to={`/${pages[index]}`}>
          <FAB className="menu-fab" variant="extended" color="secondary" aria-label="add" style={{position: 'fixed', bottom: 20, right: 20, outline: 'none', backgroundColor: '#8d1b38'}}>
            {(pages[index].replace(/-/g, " ") === 'mission vision') ? 'mission & vision' : pages[index].replace(/-/g, " ")}
          </FAB>
        </Link>
      </>
    )
  }

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"/>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.1.0/css/all.css" />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      {(open === false)
        ? <>
            <ShowLeftFab currentPage={pageName} pages={pages} index={lastPageIndex} />
            <ShowRightFab currentPage={pageName} pages={pages} index={nextPageIndex} />
          </> 
        : null
      }
      <FAB className="menu-fab" onClick={toggleDrawer(!open)} color="secondary" aria-label="add" style={{position: 'fixed', top: 20, right: 20, outline: 'none', backgroundColor: '#8d1b38'}}>
        <Icon className={'fa fa-bars'} />
      </FAB>
      <Sidenav />
      <SwipeableDrawer className="swipe-drawer" open={open} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
        <Sidenav toggleDrawer={toggleDrawer} />
      </SwipeableDrawer>
      <div className="main">{children}</div>
      <RightBar />
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
