import { Link } from "gatsby"
import React from 'react';
import { NavDropdown } from 'react-bootstrap';

import './style.css';
import './layout.css'


const Sidenav = (props) => (
  <>
  {
    (props.toggleDrawer)
    ? <div className="sidenav" role="presentation" onClick={props.toggleDrawer(false)} onKeyDown={props.toggleDrawer(false)}>
    <h3 style={styles.sidenavHeader}>Where You <span style={{fontStyle: 'italic'}}>and</span> Your Money Are Treated Best</h3>
    <div style={{textAlign: 'center', backgroundColor: '#bdb7a1'}}>
      <Link to="/" className="headerLink" >
        Home
      </Link> &nbsp; | &nbsp;
      <Link to="/contact" className="headerLink" >
        Contact Us
      </Link>&nbsp; | &nbsp;
      {/* <Link to="/get-started" className="headerLink" >
        Coastal University
      </Link>&nbsp; | &nbsp; */}
      <Link to="/login" className="headerLink" >
        Client Log-in
      </Link>
    </div>
    <Link to="/awards" className="sidenavLink" >
      Awards
    </Link>
    <Link to="/philosophy" className="sidenavLink">
      Our Investment Philosophy & Strategy
    </Link>
    <Link to="/client-services" className="sidenavLink" >
      Client Services
    </Link>
    <Link to="/account-services" className="sidenavLink" >
      Account Services
    </Link>
    <Link to="/case-studies" className="sidenavLink" >
      Case Studies
    </Link>
    <Link to="/values" className="sidenavLink" >
      Our Values
    </Link>
    <Link to="/mission-vision" className="sidenavLink" >
      Mission & Vision
    </Link>
    <Link to="/why-coastal" className="sidenavLink" >
      Why Coastal Advisory Group
    </Link>
    <Link to="/about" className="sidenavLink" >
      About Us
    </Link>
    <Link to="/michael-mueller" className="sidenavLink" >
      Michael Mueller, President
    </Link>
    <Link to="/crew" className="sidenavLink" >
      Our Crew
    </Link>
    <Link to="/get-started" className="sidenavLink" style={{paddingBottom: '40px'}} >
      Get Started
    </Link>
  </div>
    : <div className="sidenavLg">
    <h3 style={styles.sidenavHeader}>Where You <span style={{fontStyle: 'italic'}}>and</span> Your Money Are Treated Best</h3>
    <Link to="/awards" className="sidenavLink" >
      Awards
    </Link>
    <Link to="/philosophy" className="sidenavLink">
      Our Investment Philosophy & Strategy
    </Link>
    <Link to="/client-services" className="sidenavLink" >
      Client Services
    </Link>
    <Link to="/account-services" className="sidenavLink" >
      Account Services
    </Link>
    <Link to="/case-studies" className="sidenavLink" >
      Case Studies
    </Link>
    <Link to="/values" className="sidenavLink" >
      Our Values
    </Link>
    <Link to="/mission-vision" className="sidenavLink" >
      Mission & Vision
    </Link>
    <Link to="/why-coastal" className="sidenavLink" >
      Why Coastal Advisory Group
    </Link>
    <NavDropdown className="sidenavLink" title="About Us" id="nav-dropdown">
      <NavDropdown.Item><Link to="/about">About Us</Link></NavDropdown.Item>
      <NavDropdown.Item><Link to="/michael-mueller">Michael Mueller, President</Link></NavDropdown.Item>
      <NavDropdown.Item><Link to="/crew">Our Crew</Link></NavDropdown.Item>
    </NavDropdown>
    <Link to="/get-started" className="sidenavLink">
      Get Started
    </Link>
  </div>

  }
   </>
)

let styles = {
  sidenavHeader: {
    textAlign: 'center',
    color: '#8d1b38',
    padding: '20px 7px 15px 7px'
  }
}

export default Sidenav